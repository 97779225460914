import { createMuiTheme } from '@material-ui/core/styles'

const purplePrimary = '#b19cd9'
const pinkSecondary = '#dea5a4'
const whiteSecondary = '#ffffff'
const redSecondary = '#ff6961'

const primaryText = '#444444'
// A custom theme for this app
const theme = createMuiTheme({
  typography: {
    h1: {
      fontFamily: ['Dancing Script'].join(',')
    },
    h2: {
      fontFamily: ['Dancing Script'].join(',')
    },
    h3: {
      fontFamily: ['Dancing Script'].join(',')
    },
    h4: {
      fontFamily: ['Dancing Script'].join(',')
    },
    h5: {
      fontFamily: ['Dancing Script'].join(',')
    },
    h6: {
      fontFamily: ['Dancing Script'].join(',')
    }
  },
  palette: {
    primary: {
      main: purplePrimary
    },
    secondary: {
      main: pinkSecondary
    },
    error: {
      main: redSecondary
    },
    background: {
      default: whiteSecondary
    },
    text: {
      primary: primaryText
    }
  }
})

export { theme }
